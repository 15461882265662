.progress {
    all: initial;
    display: flex;
    height: 3px;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;

    &--hidden {
        display: none;
    }

    &-bar {
        transition: .6s;
    }
}
