.main-content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .container-fluid[id=app] {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
