//
// Modal
//


.modal-title {
    font-size: $modal-title-font-size;
}


// Fluid modal

.modal-fluid {
    .modal-dialog {
        margin-top: 0;
        margin-bottom: 0;
    }

    .modal-content {
        border-radius: 0;
    }
}


// Background color variations

@each $color, $value in $theme-colors {
    .modal-#{$color} {
        @include modal-variant($value);
    }
}

.crop-modal {
    max-width: 700px;
    &__top {
        padding: 30px 20px;
        border-bottom: 2px solid #F0EDFD;
    }
    &__content {
        padding: 30px 20px;
    }
    &__title {
        margin: 0;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
    }
    &__btns {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: -5px;
        margin-top: 30px;
        .btn {
            max-width: 205px;
            height: 46px;
            margin: 5px;
        }
    }
    &__preview {
        max-height: 400px;
        width: auto;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        .cropper-modal {
            opacity: .8;
        }
        .cropper-bg {
            background: none;
        }
        .cropper-view-box {
            overflow: hidden;
        }
        .cropper-line {
            opacity: .01;
        }
        .cropper-point {
            display: none;
        }
        .cropper-view-box {
            outline: none;
        }
        .cropper-face {
            background: none;
        }
        .cropper-dashed {
            border: none;
        }
        &_sm {
            width: 45px;
            height: 45px;
            overflow: hidden;
            border-radius: 14px;
            margin: 0 10px;
        }
        &_md {
            width: 70px;
            height: 70px;
            overflow: hidden;
            border-radius: 14px;
            margin: 0 10px;
        }
    }
    &__previews {
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }
}
