.gallery {
    &__upload {
        position: relative;
        display: flex;
        flex-direction: column;

        &__content {
            background-color: #fff;
            border: 1px dashed #dee2e6;
            border-radius: 0.375rem;
            text-align: center;
            color: #8898aa;
            transition: all 0.15s ease;
            order: -1;
            cursor: pointer;
            z-index: 999;

            padding-top: 2rem;
            padding-bottom: 2rem;

            &:hover, &--active {
                border-color: #8898aa;
                color: #525f7f;
            }
        }
    }

    &__images {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

