.grid {
    &__image {
        max-width: 250px;
        height: auto;
    }

    &__icon {
        border: 0;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        background-color: transparent;
        cursor: pointer;
        position: relative;
        text-transform: none;
        will-change: transform;
    }
}
