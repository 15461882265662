.carousel {
    &-item {
        height: 300px;

        .table & {
            height: 150px;
        }

        &-image {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    .carousel-control-next-icon,.carousel-control-prev-icon {
        filter: invert(1);
    }
}
